.proposalDetailContainer {
  padding-top: 1rem;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;
}

.proposalDetailLineItems {
  grid-column: span 1;
  display: flex;
  flex-direction: column;
}

.proposalLineItem {
  width: 100%;
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 1fr;
}

.proposalLineItemModifiers {
  display: flex;
  gap: 0.5rem;
  align-items: start;
  align-self: start;
}

.editProposalForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.deleteLineButton {
  align-self: center;
}

.proposalDetailCustomerContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

@media (min-width: 600px) {
  .proposalDetailContainer {
    grid-template-columns: 1fr 1fr;
  }
  .proposalDetailLineItems {
    grid-column: span 2;
  }
  .proposalLineItem {
    grid-template-columns: 1fr 1fr;
  }
}

.termsContainer {
  padding-top: 4rem;
  width: 90%;
  position: relative;
}

.closeButton {
  position: absolute !important;
  top: 0.5rem;
  right: 1rem;
}

.signature {
  border: solid #000 1px;
  touch-action: none;
  width: min-content;
}
