.page {
    height: 100vh;
    display: grid;
}

.split {
    display: grid;
    grid-template-columns: 300px 1fr;
    height: calc(100vh - 50px);
}

.sidebar {
    overflow-y: auto;
    height: 100%;
}

.viewer {

    background-color: #ffffff;
    margin: 0.5em;
    padding: 0.5em;
    border-radius: 0.5em;
}

.viewerContainer {
    background-color: #bbbbbb;    
    height: 100%;
    overflow-y: auto;
}