.controlHeader {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 1rem 0;
}

.controlHeaderButtonContainer {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}


.selected {
  background-color: #0000ff;
}